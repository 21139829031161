import React from "react";
import { Box, Typography, Container } from "@mui/material";
import { Subscribe } from "../Subscribe";

export const MainBody = () => {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "1rem",
        width: "100%",
        height: "100%",
        marginTop: "4rem",
      }}
    >
      <Box
        sx={{
          height: "80vh",
          width: {
            xs: "100vw",
            sm: "60vw",
            md: "60vw",
            lg: "40vw",
            xl: "40vw",
          },
          display: {
            xs: "none",
            sm: "flex",
            md: "flex",
            lg: "flex",
            xl: "flex",
          },
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <img
          src={process.env.PUBLIC_URL + `/assets/rajendraimage.png`}
          alt="placeholder"
          style={{
            width: "100%",
            height: "100%",
            // objectFit: "cover",
            // objectPosition: "center",
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundImage: {
            xs: `url(${process.env.PUBLIC_URL}/assets/rajendraimage.png)`,
            sm: "none",
            md: "none",
            lg: "none",
            xl: "none",
          },
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "top",
          height: {
            xs: "75vh",
            sm: "",
            md: "",
            lg: "",
            xl: "",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: "10%",
            // left: "38%",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              textAlign: "center",
              fontSize: {
                xs: "30px",
                sm: "40px",
                md: "45px",
                lg: "55px",
                xl: "60px",
              },
              fontWeight: "600",
              letterSpacing: "0.3rem",
              textShadow: {
                xs: "0px 0px 10px white",
                sm: "none",
                md: "none",
                lg: "none",
                xl: "none",
              },
            }}
          >
            Rajendra Mahto
          </Typography>
          <Typography
            variant="h4"
            sx={{
              textAlign: "center",
              fontSize: {
                xs: "13px",
                sm: "15px",
                md: "18px",
                lg: "20px",
                xl: "20px",
              },
              fontWeight: "600",
              textShadow: {
                xs: "0px 0px 10px white",
                sm: "none",
                md: "none",
                lg: "none",
                xl: "none",
              },
            }}
          >
            Former Deputy Prime Minister
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: {
              xs: "12rem",
              sm: "7rem",
              md: "7rem",
              lg: "7rem",
              xl: "7rem",
            },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              textAlign: "center",
              fontSize: {
                xs: "15px",
                sm: "17px",
                md: "18px",
                lg: "20px",
                xl: "20px",
              },
              fontWeight: "600",
              color: "black",
              textShadow: {
                xs: "0px 0px 10px white",
                sm: "none",
                md: "none",
                lg: "none",
                xl: "none",
              },
            }}
          >
            Portfolio Website
          </Typography>
          <Typography
            variant="h4"
            sx={{
              textAlign: "center",
              textShadow: {
                xs: "0px 0px 10px white",
                sm: "none",
                md: "none",
                lg: "none",
                xl: "none",
              },
              fontSize: {
                xs: "40px",
                sm: "45px",
                md: "50px",
                lg: "55px",
                xl: "60px",
              },
              fontWeight: "600",
              letterSpacing: "0.5rem",
              color: "black",
            }}
          >
            COMING SOON
          </Typography>
          <Box
            sx={{
              marginTop: {
                xs: "40px",
                sm: "none",
                md: "none",
                lg: "none",
                xl: "none",
              },
            }}
          >
            <Subscribe />
          </Box>
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: {
              xs: "5%",
              sm: "1%",
              md: "1%",
              lg: "1%",
              xl: "1%",
            },
            // left: {
            //   xs: "50%",
            //   sm: "65%",
            //   md: "63%",
            //   lg: "60%",
            //   xl: "60%",
            // },

            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              color: {
                xs: "white",
                sm: "black",
                md: "black",
                lg: "black",
                xl: "black",
              },
              fontSize: {
                xs: "10px",
                sm: "10px",
                md: "12px",
                lg: "15px",
                xl: "15px",
              },
              fontWeight: "500",
              display: "flex",
              alignItems: "center", // Center align the content vertically
            }}
          >
            All Rights Reserved : {""}
          </Typography>
          <a
            href="https://onewindowintl.com/"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none", color: "black" }}
          >
            <img
              src={process.env.PUBLIC_URL + "./assets/comp_logo.png"}
              alt="..."
              style={{
                width: "20px",
                height: "25px",
                marginLeft: "5px",
              }}
            />
          </a>
        </Box>
      </Box>
    </Container>
  );
};
