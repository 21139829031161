import React from "react";
import { Box, Typography } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";

export const TopIcons = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: "1rem",
          width: "100%",
          height: "100%",
          padding: {
            xs: "1.5rem 1rem 1rem 2rem",
            sm: "1.5rem 1rem 1rem 3rem",
            md: "1.5rem 1rem 1rem 3rem",
            lg: "1.5rem 1rem 1rem 5rem",
            xl: "1.5rem 1rem 1rem 5rem",
          },
        }}
      >
        <a
          href="https://www.facebook.com/officialrajendramahto/"
          target="_blank"
          rel="noreferrer"
        >
          <FacebookIcon fontSize="large" sx={{ color: "#384858" }} />
        </a>
        <a
          href="https://twitter.com/officialrmahto?lang=en"
          target="_blank"
          rel="noreferrer"
        >
          <TwitterIcon fontSize="large" sx={{ color: "#384858" }} />
        </a>
      </Box>
    </Box>
  );
};
