import "./App.css";
import { TopIcons } from "./TopIcons";
import { MainBody } from "./MainBody/MainBody";

function App() {
  return (
    <div className="App">
      <TopIcons />
      <MainBody />
    </div>
  );
}

export default App;
